<template>
  <div>
    <div v-if="!isHomePage" class="nav-bar-height" />
    <header v-if="$slots.header">
      <slot name="header" />
    </header>
    <main v-if="$slots.main" class="pb-16 pt-10 md:py-28 xl:pb-32">
      <slot name="main" />
    </main>
    <PageFooter />
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()

const isHomePage = computed(() => route.path === '/')
</script>
